// 
// footer.scss
//

.footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: $footer-height;
    display: flex;
    align-items: center;
    padding: 0 $spacer;
    color: var(--#{$prefix}emphasis-color);
    transition: $transition-base;
    border-top: 1px solid $card-border-color;

    .footer-links {
        a {
            color: var(--#{$prefix}emphasis-color);
            margin-left: $spacer;
            transition: all .4s;

            &:hover {
                color: var(--#{$prefix}emphasis-color);
            }

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}

// Used for account authentication pages
.footer-alt {
    border: none;
    text-align: center;
    justify-content: center;
}


html[data-layout-mode="detached"]:not([data-layout="topnav"]) {
    .footer {
        .container-fluid {
            max-width: 100%;
            padding: 0;
        }
    }
}

@include media-breakpoint-down(md) {
    .footer {
        text-align: center;
    }
}