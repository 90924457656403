//
// email.scss
//

.file-offcanvas {
    --#{$prefix}offcanvas-width: 300px;
}

.email-border {
    --#{$prefix}border-color: var(--#{$prefix}body-bg);
}

.email-list {
    display: block;
    padding-left: 0;
    overflow: hidden;

    >li {
        position: relative;
        display: block;
        height: 51px;
        line-height: 50px;
        cursor: default;
        transition-duration: 0.3s;

        a {
            color: var(--#{$prefix}body-color);

            &:hover {
                color: var(--#{$prefix}tertiary-color);
            }
        }

        .col-mail {
            float: left;
            position: relative;
        }

        .email-sender-info {
            width: 320px;

            .star-toggle,
            .checkbox-wrapper-mail {
                display: block;
                float: left;
            }

            .checkbox-wrapper-mail {
                margin: 15px 10px 0 20px;
                cursor: pointer;
                height: 20px;
                width: 20px;
            }

            .star-toggle {
                color: var(--#{$prefix}dark-border-subtle);
                margin-left: 10px;
                font-size: 18px;
            }

            .email-title {
                position: absolute;
                top: 0;
                left: 100px;
                right: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-bottom: 0;
                line-height: 50px;
            }
        }

        .email-content {
            position: absolute;
            top: 0;
            left: 320px;
            right: 0;
            bottom: 0;

            .email-subject,
            .email-date {
                position: absolute;
                top: 0;
            }

            .email-subject {
                left: 0;
                right: 110px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .email-date {
                right: 0;
                width: 100px;
                text-align: right;
                padding-left: 10px;
            }
        }

        &.active,
        &.mail-selected {
            background: var(--#{$prefix}tertiary-bg);
            transition-duration: 0.05s;
        }

        &.unread {
            a {
                font-weight: $font-weight-semibold;
                color: var(--#{$prefix}dark);
            }
        }

        .email-action-icons {
            opacity: 0;

            ul {
                display: flex;
                position: absolute;
                transition: all 0.5s;
                right: -180px;

                li {
                    margin: 0 10px;
                }

                .email-action-icons-item {
                    font-size: 20px;
                    transition: all 0.5s;

                    &:hover {
                        color: $danger;
                    }
                }
            }
        }

        &:hover {
            background: var(--#{$prefix}tertiary-bg);
            transition-duration: 0.05s;

            .email-action-icons {
                opacity: 1;

                ul {
                    transition: all 0.5s;
                    right: 10px;
                }
            }

            .email-content {
                .email-date {
                    opacity: 0;
                }

                .email-subject {
                    right: 180px;
                    transition: all 0.5s;
                }
            }
        }
    }
}

.email-menu-list {
    a {
        color: var(--#{$prefix}secondary-color);
        padding: 12px 5px;
        display: block;
        font-size: 15px;

        &:hover {
            color: var(--#{$prefix}tertiary-color);
        }

        .badge {
            margin-top: 3px;
        }
    }
}

.labels-list {
    a {
        padding: 7px 5px;
    }
}

.write-mdg-box {
    .CodeMirror {
        height: 150px;
    }
}

@media (max-width: 648px) {
    .email-list {
        li {
            .email-sender-info {
                .checkbox-wrapper-mail {
                    margin-left: 0;
                }
            }
        }
    }
}

@media (max-width: 520px) {
    .email-list {
        li {
            .email-sender-info {
                width: 150px;

                .email-title {
                    left: 80px;
                }
            }

            .email-content {
                display: none;
            }
        }
    }
}