// 
// _root.scss
//


// Theme Light Mode
:root,
[data-bs-theme="light"] {
    --#{$prefix}input-bg:                       #{$white};
}

// Theme Dark Mode
@if $enable-dark-mode {
    @include color-mode(dark, true) {
        --#{$prefix}light:                          #464f5b;
        --#{$prefix}light-rgb:                      #{to-rgb(#464f5b)};
        
        --#{$prefix}dark:                           #f1f1f1;
        --#{$prefix}dark-rgb:                       #{to-rgb(#f1f1f1)};

        --#{$prefix}secondary:                      #{#6c757d};
        --#{$prefix}secondary-rgb:                  #{to-rgb(#6c757d)};

        --#{$prefix}input-bg:                       #{#404954};

        --#{$prefix}box-shadow:                     #{0px 0px 35px 0px rgba(49, 57, 66, 0.5)};
    }
}
