//
// chartjs.scss
//

.chartjs-chart {
    margin: auto;
    position: relative;
    width: 100%;
}

.e-charts {
    height: 350px;
}